/* -------------------------------------------------------------------------------------/*
Glen Nepomuceno CSS framework
Ver: 1.0 \ Author URI: glennepomuceno.com
/* -------------------------------------------------------------------------------------*/

/*-------------- Plugins --------------*/
// @import "./plugins/regular.scss";

/*-------------- Utilities --------------*/
@import "./utilities/settings";
// @import './utilities/grid';

/*-------------- Layout --------------*/
// @import './layout/base';
@import "./layout/main";
// @import './layout/misc';
// @import './layout/footer';
// @import './layout/homepage';

/*-------------- Pages --------------*/
// @import './pages/product-page';
// @import './pages/checkout';

/*-------------- Modules --------------*/
// @import './modules/btn';

/*-------------- Mobile --------------*/
@import "./mobile/mobile";
