@include desktop-1024 {
  .gct-streaming--start {
    padding: 420px 0 92px;
  }

  .gct-streaming-main--content-item__canvas {
    height: 376px;
  }

  .gct-streaming-main--content-item__text {
    height: 158px;
  }

  .gct-streaming-page-item {
    &--head {
      &__title {
        a {
          left: 14px;
        }
      }
    }

    &--content {
      &__frame {
        width: 97%;
        left: 1.5%;
      }

      &__canvas {
        width: 842px;
        height: 630px;
        left: 49.8%;
        transform: translateX(-50%);
        top: 5.5%;
      }

      .sound-icon {
        top: 638px;
        left: 82px;
      }

      .expand-icon {
        top: 638px;
        right: 86px;
      }

      &__video {
        margin-bottom: 286px;
      }
    }
  }
}

// iPhone X - Landscape
@include mobile-landscape-812 {
  .gct-streaming-main {
    &--head {
      &__text {
        width: 88%;
        text-align: justify;
      }
    }

    &--content-item {
      width: 100%;
      padding: 0 16px;

      &__canvas {
        height: 586px;
        max-height: 586px;
      }
    }
  }

  .gct-streaming-page-item {
    &--content {
      &__canvas {
        width: 682px;
        height: 512px;
        top: 5%;
      }

      .sound-icon {
        top: 512px;
        left: 70px;
      }

      .expand-icon {
        top: 512px;
        right: 73px;
      }

      &__video {
        height: 540px;
      }
    }
  }
}

// iPad
@include tablet-768 {
  .gct-streaming {
    background-size: 190%;

    &::after {
      background-position: 62% 100%;
      background-size: 112%;
    }

    &--std {
      padding-top: 540px;
    }

    &--std-pages {
      padding-top: 540px;
    }
  }

  .gct-streaming-main {
    &--head {
      &__text {
        width: 88%;
        text-align: justify;
      }
    }

    &--content-item {
      width: 100%;
      padding: 0 16px;

      &__canvas {
        height: 554px;
      }

      &__text {
        height: auto;
      }
    }
  }

  .gct-streaming-page-item {
    &--content {
      &__canvas {
        width: 644px;
        height: 484px;
        top: 4.9%;
      }

      .sound-icon {
        top: 483px;
        left: 69px;
      }

      .expand-icon {
        top: 483px;
        right: 69px;
      }

      &__video {
        height: 520px;
        margin-bottom: 260px;
      }

      &__text {
        width: 94%;
        margin: 0 auto;
        text-align: justify;
      }
    }
  }

  .nav-streaming {
    padding-right: 17px;
  }

  .main-footer {
    p {
      max-width: 630px;
      margin: 0 auto;
    }

    &--v-divider {
      display: none;
    }
  }
}

// iPhone X Max Landscape
@include mobile-landscape-736 {
  .gct-streaming-main {
    &--content-item {
      &__canvas {
        height: 530px;
      }
    }
  }

  .gct-streaming-page-item {
    &--content {
      &__canvas {
        width: 618px;
        height: 462px;
        top: 4.7%;
      }

      .sound-icon {
        top: 461px;
        left: 64px;
      }

      .expand-icon {
        top: 461px;
        right: 67px;
      }

      &__video {
        margin-bottom: 220px;
      }
    }
  }
}

// Tablet Small
@include tablet-720 {
  .gct-streaming-start-page {
    min-height: auto;
  }

  .gct-streaming-main {
    &--content-item {
      &__canvas {
        height: 518px;
      }
    }
  }

  .gct-streaming-page-item {
    &--content {
      &__canvas {
        width: 603px;
        height: 452px;
        top: 4.2%;
      }

      .sound-icon {
        top: 452px;
        left: 64px;
      }

      .expand-icon {
        top: 452px;
        right: 64px;
      }

      &__video {
        margin-bottom: 220px;
      }
    }
  }
}

// Mobile - iPhone 8 Landscape
@include mobile-landscape-667 {
  .gct-streaming-main {
    &--content-item {
      &__canvas {
        height: 480px;
      }
    }
  }
  .gct-streaming-page-item {
    &--content {
      &__canvas {
        width: 563px;
        height: 421px;
        top: 3.3%;
      }

      .sound-icon {
        top: 417px;
        left: 58px;
      }

      .expand-icon {
        top: 417px;
        right: 60px;
      }

      &__video {
        margin-bottom: 176px;
      }
    }

    &--content__frame-bg {
      height: 36%;
    }
  }
}

// Mobile - Large
@include mobile-480 {
  .gct-streaming {
    &--std {
      padding-top: 358px;
    }

    &--std-pages {
      padding-top: 364px;
    }

    &--start {
      padding: 336px 0 80px;
    }
  }

  .gct-streaming-main {
    &--content-item {
      &__canvas {
        height: 338px;
      }
      &__text {
        padding: 14px 20px;
        text-align: justify;
      }
    }
  }

  .gct-streaming-page-item {
    &--head {
      &__title {
        a {
          padding-right: 1px;
          font-size: 16px;

          i {
            padding-right: 1px;
          }
        }
        h1 {
          font-size: 32px;
          margin-bottom: 26px;
        }
      }
    }

    &--content {
      &__canvas {
        width: 406px;
        height: 303px;
        top: 1.4%;
      }

      &__icon {
        i {
          font-size: 20px;
        }
      }

      .sound-icon {
        top: 296px;
        left: 41px;

        &.unmute {
          &::after {
            font-size: 20px;
            left: -30px;
          }
        }
      }

      .expand-icon {
        top: 296px;
        right: 41px;
      }

      &__video {
        margin-bottom: 122px;
        height: 380px;
      }
    }

    &--content__frame-bg {
      height: 21%;
    }
  }

  .gct-streaming--card {
    width: 88%;
    padding: 40px 20px;

    &__text {
      text-align: center;
      max-width: 324px;
      margin: 0 auto 20px;
    }
  }

  .main-footer {
    p {
      max-width: 280px;
    }
  }
}

// Mobile - Medium
@include mobile-425 {
  .gct-streaming {
    &--std {
      padding-top: 266px;
    }
    &--std-pages {
      padding-top: 300px;
    }
  }

  .gct-streaming-main {
    &--content-item {
      &__canvas {
        height: 296px;
      }
    }
  }

  .gct-streaming-page-item {
    &--content {
      &__canvas {
        width: 360px;
        height: 268px;
        top: 0.75%;
      }

      .sound-icon {
        top: 259px;
        left: 37px;
      }

      .expand-icon {
        top: 259px;
        right: 37px;
      }

      &__video {
        height: 326px;
      }
    }
  }

  .gct-streaming--card {
    &__form {
      width: 96%;
    }
  }
}

// Mobile - iPhone Max
@include mobile-414 {
  .gct-streaming-main {
    &--content-item {
      &__canvas {
        height: 288px;
      }
    }
  }

  .gct-streaming-page-item {
    &--content {
      &__canvas {
        width: 349px;
        height: 260px;
        top: 0.4%;
      }

      .sound-icon {
        top: 252px;
        left: 36px;
      }

      .expand-icon {
        top: 252px;
        right: 36px;
      }

      &__video {
        margin-bottom: 110px;
      }
    }
  }

  .gct-subscription-select {
    label {
      padding: 8px 22px;
    }
  }
}

// Mobile - iPhone
@include mobile-375 {
  .gct-streaming {
    &--std-pages {
      padding-top: 244px;
    }
    &--start {
      padding: 280px 0 80px;
    }
  }

  .gct-streaming-main {
    &--content-item {
      &__canvas {
        height: 258px;
      }
    }
  }

  .gct-streaming-page-item {
    &--head {
      &__title {
        h1 {
          font-size: 27px;
        }

        a {
          font-size: 15px;
        }
      }
    }
    &--content {
      &__canvas {
        width: 316px;
        height: 234px;
        top: -0.5%;
      }

      .sound-icon {
        top: 227px;
        left: 33px;
      }

      .expand-icon {
        top: 227px;
        right: 33px;
      }
      &__video {
        height: 286px;
      }
    }

    &--content__frame-bg {
      height: 20%;
    }
  }
}

// Mobile - Samsung S5
@include mobile-360 {
  .gct-streaming-main {
    &--content-item {
      &__canvas {
        height: 250px;
      }
    }
  }

  .gct-streaming-page-item {
    &--content {
      &__canvas {
        width: 303px;
        height: 226px;
        top: -0.85%;
      }

      .sound-icon {
        top: 216px;
        left: 31px;
      }

      .expand-icon {
        top: 216px;
        right: 31px;
      }
      &__video {
        margin-bottom: 92px;
      }
    }
  }
}
