/* --------------------------------
 Main
* ---------------------------------*/
body.modal-open {
  height: 100vh;
  overflow-y: hidden;
}

.container-wrap-1200 {
  max-width: 1200px;
  margin: 0 auto;
}

.container-wrap-998 {
  max-width: 998px;
  margin: 0 auto;
}

body {
  background: #000;
}

.gct-streaming {
  background: url("https://www.ghost-crime-tours.com.au/images/streaming/kapunda-gct-bg-hero.jpg")
    no-repeat;
  background-position-x: center;
  display: block;
  padding-bottom: 90px;
  position: relative;

  &--std {
    padding-top: 720px;
    position: relative;
  }
  &--std-pages {
    padding-top: 662px;
  }

  &::after {
    content: "";
    background: url("https://www.ghost-crime-tours.com.au/images/streaming/kapunda-gct-bg-footer.jpg")
      no-repeat;
    background-size: cover;
    position: absolute;
    height: 1250px;
    width: 100%;
    bottom: 0;
    z-index: -9;
  }
}

.gct-streaming-main {
  &--head {
    &__title {
      position: relative;
      text-align: center;

      h1 {
        padding: 0;
        font-size: 46px;
        // text-shadow: #e0e0e0 1px 1px 0; As per feeback
        background: none;
      }

      img {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        width: 100px;
        height: auto;
        padding-left: 12px;
      }
    }

    &__text {
      max-width: 830px;
      display: block;
      margin: 0 auto 30px;

      p {
        margin-bottom: 0.65rem;
        text-align: justify;
      }
    }
  }

  &--content-wrap {
    display: flex;
    flex-wrap: wrap;
  }

  &--content-item {
    width: 50%;
    padding: 0 6px;
    margin-bottom: 36px;

    &__title {
      text-transform: uppercase;
      font-family: "Oswald", Arial, sans-serif;
      color: #dd9427;
      font-size: 32px;
      font-weight: 600;
      margin-bottom: 10px;
      // text-shadow: #e0e0e0 1px 1px 0;
      // text-shadow: 1px 1px 6px rgba(255, 255, 255, 0.69);
      display: inline-block;

      a {
        // background: rgba(0, 0, 0, 0.4); As per feedback
        cursor: pointer;
        padding: 10px;
        display: inline-block;
        text-align: center;
      }
    }
    &__video {
      display: inline-block;
      width: 100%;

      a {
        cursor: pointer;
      }
    }

    &__canvas {
      border: 2px solid #dd9427;
      margin: 0;
      width: 100%;
      height: 442px;
      max-height: 442px;
      background: #000;
      outline: none;
    }

    &__text {
      margin: 0 auto;
      padding: 14px 30px;
      text-align: justify;
      background: rgba($color: #000000, $alpha: 0.6);
      height: 130px;

      p {
        margin-bottom: 0;
      }
    }
  }
}

.gct-streaming-page-item {
  &--head {
    &__title {
      position: relative;
      text-align: center;

      h1 {
        padding: 0;
        font-size: 48px;
        margin-bottom: 46px;
        background: none;
      }

      a {
        position: absolute;
        top: 50%;
        left: 0;
        transform: translateY(-50%);
        height: auto;
        font-family: "Oswald", Arial, sans-serif;
        color: #dd9427;
        font-size: 24px;
        font-weight: 600;
        line-height: 1.2em;
        cursor: pointer;
      }
    }
  }

  &--content-wrap {
    position: relative;
  }

  &--content {
    &__frame-bg {
      position: absolute;
      background: #000;
      height: 50%;
      width: 93%;
      top: 0%;
      left: 50%;
      transform: translate(-50%);
    }

    &__frame {
      position: absolute;
      width: 104%;
      height: auto;
      left: -2%;
      top: 0;
      z-index: 2;
    }

    &__video {
      position: relative;
      height: 730px;
      background-size: cover;
      z-index: 3;
      margin-bottom: 272px;
      padding-top: 71px;
      top: 28px;
      width: 92%;
      margin: 0 auto 360px;

      &::after {
        content: "";
        background: #000000;
        position: absolute;
        top: 0;
      }
    }

    &__icon {
      color: #fff;
      position: absolute;
      z-index: 5;

      font-size: 25px;
      cursor: pointer;

      &:hover {
        color: #dd9427;
        cursor: pointer;
      }
    }

    .close-icon {
      top: 14px;
      right: 14px;
      position: fixed;
      z-index: 3;
      display: none;

      &:after {
        content: "";
        position: absolute;
        background: #000;
        height: 17px;
        width: 17px;
        display: block;
        border-radius: 50%;
        top: 5px;
        right: 4px;
        z-index: -1;
      }
    }

    .expand-icon {
      top: 683px;
      right: 62px;
    }

    .sound-icon {
      top: 683px;
      left: 62px;

      &.unmute {
        &::after {
          content: "\f715";
          font-family: "Font Awesome 5 Free";
          position: relative;
          left: -36px;
        }
      }
    }

    &__canvas {
      position: absolute;
      width: 900px;
      height: 676px;
      margin: 0 auto;
      left: 49.9%;
      top: 44px;
      transform: translateX(-50%);
      outline: none;
      background: #000;

      &:-webkit-full-screen {
        object-fit: contain;
        position: fixed !important;
        top: 0px !important;
        right: 0px !important;
        bottom: 0px !important;
        left: 0px !important;
        box-sizing: border-box !important;
        min-width: 0px !important;
        max-width: none !important;
        min-height: 0px !important;
        max-height: none !important;
        width: 100% !important;
        height: 100% !important;
        transform: none !important;
        margin: 0px !important;
        background: #000;
        z-index: 2;
      }

      &.canvas-full {
        object-fit: contain;
        position: fixed !important;
        top: 0px !important;
        right: 0px !important;
        bottom: 0px !important;
        left: 0px !important;
        box-sizing: border-box !important;
        min-width: 0px !important;
        max-width: none !important;
        min-height: 0px !important;
        max-height: none !important;
        width: 100% !important;
        height: 100% !important;
        transform: none !important;
        margin: 0px !important;
        background: #000;
        z-index: 2;
      }
    }

    &__title {
      text-align: center;
      text-shadow: #e0e0e0 1px 1px 0;
      margin: 0 auto 20px;
      display: block;
      font-size: 48px;
    }

    &__text {
      background: rgba(0, 0, 0, 0.74);
      padding: 34px;

      p:last-child {
        margin-bottom: 0;
      }
    }
  }
}

canvas {
  display: block;
  float: left;
  width: calc(50vw - 40px);
  height: calc(50vh - 40px);
  margin: 10px;
}

.gct-streaming-start-page {
  background: url("https://www.ghost-crime-tours.com.au/images/streaming/kapunda-gct-bg-2.jpg")
    no-repeat;
  background-size: cover;
  background-position-x: center;
  min-height: 100vh;
  padding-bottom: 0;
}

.gct-streaming--start {
  padding: 548px 0 140px;
}

.gct-streaming--card {
  background: #000;
  width: 520px;
  margin: 0 auto;
  border: 2px solid #dd9427;
  padding: 40px 52px;

  &__title {
    margin-bottom: 18px;
    text-transform: uppercase;
  }

  &__text {
    margin-bottom: 20px;
    text-align: center;
  }

  &__text-login {
    text-align: center;
    margin-bottom: 6px;
  }

  &__text-notification {
    color: #dc3545;
    text-align: center;
    margin-bottom: 6px;
    height: 25px;
  }

  &__form {
    width: 84%;
    margin: 0 auto;

    .gct-form-control {
      margin-bottom: 14px;

      input {
        height: 42px;
        width: 100%;
        padding: 10px 14px;
      }
    }

    .gct-form--btn-wrap {
      margin: 48px 0 14px;
    }
  }
}

.main-footer {
  background: #000;
  padding: 36px 0;
  font-size: 14px;
  text-align: center;

  p {
    margin-bottom: 0;
  }
}

.btn-gct-form {
  color: #fff;
  width: 100%;
  border: 1px solid #dd9427 !important;
  border-radius: 0;
  padding: 9px 20px;
  background: rgba(177, 16, 22, 0.75);
  outline: none !important;
  cursor: pointer;
  border-radius: 0 !important;

  &:hover {
    background: #4f308f;
  }
}

.streaming-nav {
  display: flex;
  position: absolute;
  top: 38px;
  right: 0;
  list-style: none;
  background: rgba(0, 0, 0, 0.4);

  li {
    &::after {
      content: "|";
    }

    &:last-child::after {
      content: "";
    }
  }
}

.nav-streaming {
  list-style: none;
  margin: 0;
  margin-bottom: 16px;
  text-align: right;
  padding-right: 7px;
  display: flex;
  flex-direction: row-reverse;

  li {
    margin-left: 8px;
  }
}

.btn-settings {
  color: #fff;
  border: 1px solid #dd9427 !important;
  border-radius: 0;
  padding: 9px 20px;
  background: rgba(177, 16, 22, 0.75);
  outline: none !important;
  cursor: pointer;
  border-radius: 0 !important;
  font-weight: normal;

  &:hover {
    background: #4f308f;
  }
}

.gct-subscription-select {
  display: flex;
  margin-bottom: 36px;
  overflow: hidden;
  width: 100%;
  position: relative;

  input {
    position: absolute !important;
    clip: rect(0, 0, 0, 0);
    height: 1px;
    width: 1px;
    border: 0;
    overflow: hidden;

    &:checked + label {
      background-color: #dd9427;
      box-shadow: none;
    }
  }

  label {
    background-color: #e4e4e4;
    color: rgba(0, 0, 0, 0.6);
    font-size: 14px;
    line-height: 1;
    text-align: center;
    padding: 8px 16px;
    margin-right: -1px;
    border: 1px solid #dd9427;
    border-radius: 0;
    transition: all 0.1s ease-in-out;
    width: 50%;

    &:hover {
      cursor: pointer;
    }
  }
}

.gct-main-notification {
  height: 100vh;
  background: rgba(0, 0, 0, 0.45);
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;

  &--card {
    background: #000;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border: 1px solid #dd9427;
    border-top: none;
    min-width: 600px;
  }

  &--head {
    display: flex;
    background: #af151e;
    justify-content: space-between;
    border-top: 1px solid #dd9427;
    border-bottom: 1px solid #dd9427;

    p {
      margin: 8px 10px;
    }
  }

  &--body {
    padding: 24px;

    p {
      text-align: center;
      margin-bottom: 10px;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  .btn-streaming-close {
    background: none;
    border: none;
    font-size: 20px;
    margin-right: 8px;
    cursor: pointer;
    position: relative;
    z-index: 3;
    outline: none;

    &:hover {
      color: #383838;
    }

    &::after {
      content: "";
      position: absolute;
      background: #ffff;
      height: 15px;
      width: 15px;
      display: block;
      border-radius: 50%;
      top: 14px;
      right: 3px;
      z-index: -1;
    }
  }
}

.gct-streaming--login-form,
.gct-streaming--sign-up-form {
  .submit-notif-submitting {
    display: none;
    position: absolute;
  }

  .gct-form--btn-wrap {
    position: relative;
  }

  &.dispatch-submitting {
    .dispatch-submit {
      cursor: auto;
      background: #4f308f;
      transition: all 0.2s ease;
      opacity: 0.8;
      color: #4f308f;

      &:hover {
        color: #4f308f;
      }
    }

    .submit-notif-submitting {
      display: inline;
      color: #fff;
      position: absolute;
      top: 24%;
      left: 50%;
      transform: translateX(-50%);
      cursor: default;
      width: 100%;
      text-align: center;

      & i {
        animation: loading 1000ms linear infinite;
        display: inline-block;
      }

      @keyframes loading {
        100% {
          transform: rotate(360deg);
        }
      }
    }
  }
}
